import React from 'react'
import BannerImageOnly from 'blocks/BannerImageOnly/template'
import TextBlockInset from 'blocks/TextBlockInset/template'
import PostBuilder from 'blocks/PostBuilder/template'
import NewsPostPagination from 'blocks/_dynamic/NewsPostPagination/template'
import Footer from 'components/Footer'
import HeaderVersionWrap from 'components/HeaderVersionWrap'
import { formatDate } from 'js/utils'

const ContentType = ({ pageId, content, locale, layoutProps }) => {
  if (!locale) locale = 'en'
  const pageContent = {
    bannerImageOnly: {
      imageSet: content.imageSet,
    },
    textBlockInset: {
      pretitle: formatDate(content.date, locale),
      title: content.title,
      excerpt: content.excerpt,
    },
    postBuilder: {
      sections: content.postBuilder,
    },
    newsPostPagination: {
      post: content,
    },
  }

  const theme = content.isDarkTheme ? 'dark' : 'light'
  return (
    <div className='page'>
      <div className={`block theme bg-${theme}`}>
        <HeaderVersionWrap
          layoutProps={layoutProps}
          isDark={content.isDarkTheme}
        >
          <BannerImageOnly
            content={pageContent.bannerImageOnly}
            layoutProps={layoutProps}
          />
        </HeaderVersionWrap>
      </div>
      <div className='block theme bg-light'>
        <HeaderVersionWrap layoutProps={layoutProps} isDark={false}>
          <TextBlockInset content={pageContent.textBlockInset} />
          <PostBuilder content={pageContent.postBuilder} />
          <NewsPostPagination
            content={pageContent.newsPostPagination}
            locale={locale}
          />
        </HeaderVersionWrap>
      </div>
      <Footer locale={locale} />
    </div>
  )
}

export default ContentType
