import React from 'react'
import ImageSet from 'components/ImageSet'
import ParallaxWrapBanner from 'components/ParallaxWrapBanner'
import Inview from 'components/Inview'
import * as styles from './styles.module.scss'

const Block = ({ content }) => {
  const { imageSet } = content
  return (
    <div className='block'>
      <div className={styles.imageWrap}>
        <Inview className={`abs-fill fade-in ${styles.image}`}>
          <ParallaxWrapBanner>
            <ImageSet content={imageSet} />
          </ParallaxWrapBanner>
        </Inview>
      </div>
    </div>
  )
}

export default Block
