import React from 'react'
import ImageSet from 'components/ImageSet'
import MarkdownWrap from 'components/MarkdownWrap'
import ParallaxWrapBanner from 'components/ParallaxWrapBanner'
import Inview from 'components/Inview'
import * as styles from './styles.module.scss'

const Block = ({ content, layoutProps }) => {
  const { pretitle, title, excerpt } = content
  return (
    <div className='block'>
      <Inview className='container fade-in up'>
        <div className={styles.block}>
          {pretitle && <h5>{pretitle}</h5>}
          {title && <h2>{title}</h2>}
          {excerpt && <MarkdownWrap body={excerpt} />}
          <div className={styles.border} />
        </div>
      </Inview>
    </div>
  )
}

export default Block
