import React from 'react'
import { StaticQuery, graphql } from 'gatsby'
import Pagination from 'blocks/Pagination/template'
import { getPaginationItems } from 'js/utils'

const Block = ({ content, data, locale }) => {
  const strings = {
    en: {
      previous: 'Previous',
      next: 'Next',
    },
    zh: {
      previous: '前一个',
      next: '下一个',
    },
  }
  const tr = strings[locale]

  const { post } = content
  const posts = data?.posts?.nodes
  const postsLocale = posts?.map((item) => item.frontmatter[locale])
  const paginationItems = getPaginationItems(post, postsLocale)
  const paginationContent = {
    prev: {
      pretitle: tr.previous,
      title: paginationItems.prevItem.title,
      link: `/news/${paginationItems.prevItem.slug}`,
    },
    next: {
      pretitle: tr.next,
      title: paginationItems.nextItem.title,
      link: `/news/${paginationItems.nextItem.slug}`,
    },
  }

  return <Pagination content={paginationContent} />
}

export default function Data({ content, locale }) {
  return (
    <StaticQuery
      query={graphql`
        query {
          posts: allMarkdownRemark(
            filter: { frontmatter: { en: { templateKey: { eq: "newsPost" } } } }
            sort: { fields: frontmatter___en___date, order: DESC }
          ) {
            nodes {
              frontmatter {
                en {
                  date
                  slug
                  title
                }
                zh {
                  date
                  slug
                  title
                }
              }
            }
          }
        }
      `}
      render={(data) => <Block content={content} data={data} locale={locale} />}
    />
  )
}
