import React from 'react'
import Inview from 'components/Inview'
import ImageWrap from 'components/ImageWrap'
import LinkWrap from 'components/LinkWrap'
import * as styles from './styles.module.scss'

const Block = ({ content }) => {
  return (
    <Inview className='pad-v-standard fade-in up'>
      <div className='container'>
        <div className={styles.itemsWrap}>
          <Item content={content.prev} direction='prev' />
          <Item content={content.next} direction='next' />
        </div>
      </div>
    </Inview>
  )
}

export default Block

const Item = ({ content, direction }) => {
  const { pretitle, title, subtitle, image, link } = content
  const directionClass = direction === 'prev' ? styles.prev : styles.next
  return (
    <LinkWrap className={`${styles.item} ${directionClass}`} to={link}>
      <div className={styles.image}>
        <ImageWrap image={image} />
      </div>
      <div className={styles.text}>
        <div className={styles.pretitle}>
          <h6>{pretitle}</h6>
          <div className={styles.line} />
        </div>
        <h4 className={styles.title}>{title}</h4>
        {subtitle && <h6>{subtitle}</h6>}
      </div>
    </LinkWrap>
  )
}
