import React from 'react'
import ImageWrap from 'components/ImageWrap'
import Inview from 'components/Inview'
import MarkdownWrap from 'components/MarkdownWrap'
import * as styles from './styles.module.scss'

const Block = ({ content }) => {
  const { sections } = content

  const components = {
    textBlock: TextBlock,
    imageBlock: ImageBlock,
  }

  return (
    <div className='block pad-v-standard'>
      <div className='container'>
        <div className={styles.wrap}>
          {sections?.map((section, i) => {
            if (components[section.type]) {
              return React.createElement(components[section.type], {
                content: section,
                key: i,
              })
            }
          })}
        </div>
      </div>
    </div>
  )
}

export default Block

const TextBlock = ({ content }) => {
  const { title, body } = content
  return (
    <Inview className={`${styles.block} fade-in up`}>
      {title && <h4>{title}</h4>}
      {body && <MarkdownWrap body={body} />}
    </Inview>
  )
}

const ImageBlock = ({ content }) => {
  const { image } = content
  return (
    <Inview className={`${styles.block} fade-in up`}>
      {image && <ImageWrap image={image} />}
    </Inview>
  )
}
